<template>
  <div id="electrical-engineering-page">
    <v-container fluid>
      <v-layout row wrap>
        <v-flex>
          <h1>{{ pageTitle }}</h1>
          <p>{{ description }}</p>
        </v-flex>
        <v-flex v-for="(s, i) in sections" :key="i">
          <h2>{{ s.title }}</h2>
          <ul v-for="(b, j) in s.body" :key="j">
            <li>{{ b }}</li>
          </ul>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import DisciplinesStore from '@/stores/DisciplinesStore.js'
export default {
  data: () => ({
    pageTitle: DisciplinesStore.data.ElectricalEngineering.title,
    description: DisciplinesStore.data.ElectricalEngineering.description,
    sections: DisciplinesStore.data.ElectricalEngineering.sections
  })
}
</script>

<style scoped>

</style>